<template>
  <div class="page">
    <Tables
      ref="xTable"
      :tableName="tableName"
      :tableData="tableData"
      :totalPage="totalPage"
      :isLoading="isLoading"
      :searchFormData="searchFormData"
      :showRefresh="true"
      :showAdd="$hasAccess('market.plan/add')"
      addName="新增人员"
      @getList="getList"
      @clearSearch="clearSearch"
      @addItemInTable="addOne"
    >
      <!--表格内容-->
      <vxe-table-column
        slot="table-item"
        field="fans_id"
        title="用户ID"
        align="center"
        min-width="100px"
      />
      <vxe-table-column
        slot="table-item"
        field="phone"
        title="手机号"
        align="center"
      />
      <vxe-table-column
        slot="table-item"
        field="name"
        title="姓名"
        align="center"
        min-width="80px"
      />
      <vxe-table-column
        slot="table-item"
        field="create_time"
        title="创建时间"
        align="center"
        min-width="80px"
      />
      <vxe-table-column
        slot="table-item"
        title="操作"
        align="center"
        width="160"
      >
        <template v-slot="{ row }">
          <el-button
            size="small"
            plain
            @click="deleteOne(row)"
          >删除</el-button>
        </template>
      </vxe-table-column>
    </Tables>

    <!-- 新增 -->
    <el-dialog
      title="新增"
      :visible.sync="addDialogShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="500px"
    >
      <el-form
        :model="addDialogFormData"
        :rules="rules"
        ref="addForm"
        label-width="100px"
        class="form"
      >
        <el-form-item
          label="姓名"
          prop="name"
        >
          <el-input
            v-model="addDialogFormData.name"
            style="width: 80%;"
          ></el-input>
        </el-form-item>
        <el-form-item
                label="手机号"
                prop="phone"
        >
          <el-input
                  v-model="addDialogFormData.phone"
                  style="width: 80%;"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          size="small"
          plain
          icon="el-icon-check"
          @click="saveAdd()"
          v-if="$hasAccess('system.extension/add')"
        >保 存
        </el-button>
        <el-button
          size="small"
          plain
          icon="el-icon-close"
          @click="closeDialog"
        >取 消
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Tables from '@/components/tables'
export default {
  name: 'market-plan-list',
  components: {
    Tables
  },
  data () {
    return {
      // 表格信息
      tableName: '推广人员',
      isLoading: false,
      tableData: [],
      totalPage: 0,
      searchFormData: {},
      // 新增
      addDialogShow: false,
      addDialogFormData: {},
      rules: {
        name: [
          { required: true, message: '请输入', trigger: 'change' }
        ],
        phone: [
          { required: true, message: '请输入', trigger: 'change' }
        ]
      }
    }
  },
  computed: {

  },
  mounted () {
  },
  activated () {
  },
  methods: {
    // 表格列表
    async getList (currentPage, pageSize) {
      const params = {
        token: this.$store.state.user.token,
        page: currentPage,
        size: pageSize
      }
      this.isLoading = true
      const res = await this.$api.System.GetExtensionList(params)
      this.tableData = res
      this.totalPage = res.total
      this.isLoading = false
    },
    // 重置搜索
    clearSearch () {
      this.searchFormData = {}
    },
    // 重置form
    resetFormData () {
      this.addDialogFormData = {

      }
      if (this.$refs.addForm) {
        this.$refs.addForm.resetFields()
      }
      if (this.$refs.editForm) {
        this.$refs.editForm.resetFields()
      }
    },
    // 新增
    addOne () {
      this.resetFormData()
      this.addDialogShow = true
    },
    // 删除
    deleteOne (row) {
      this.$confirm('确定要删除吗', '提示', {
        cancelButtonClass: 'btn-custom-cancel',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const params = {
          token: this.$store.state.user.token,
          id: row.id
        }
        await this.$api.System.DeleteExtension(params)
        this.$notify({
          title: '成功',
          message: '删除成功',
          type: 'success'
        })
        this.$refs.xTable.refreshTable()
      })
    },
    // 关闭弹窗
    closeDialog () {
      this.addDialogShow = false
      this.editDialogShow = false
    },
    // 新增保存
    saveAdd () {
      this.$refs.addForm.validate(async valid => {
        if (valid) {
          const params = {
            token: this.$store.state.user.token,
            form: JSON.stringify(this.addDialogFormData)
          }
          await this.$api.System.AddExtension(params)
          this.$notify({
            title: '成功',
            message: '新增成功',
            type: 'success'
          })
          this.closeDialog()
          this.$refs.xTable.refreshTable()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
